// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from './authentication'
import common from './commonReducer/commonStore'
import cache from './commonReducer/cache'
import user from '../views/apps/user/store/index'
import agegroups from '../views/apps/agegroups/store/index'
import attributes from '../views/apps/attributes/store/index'
import attributeSets from '../views/apps/attributeSets/store/index'
import behavioralcharacteristics from '../views/apps/behavioralcharacteristics/store/index'
import brands from '../views/apps/brands/store/index'
import category from '../views/apps/category/store/index'
import channels from '../views/apps/channels/store/index'
import colors from '../views/apps/colors/store/index'
import contentjobs from '../views/apps/contentjobs/store/index'
import contentformats from '../views/apps/contentformats/store/index'
import contenttopics from '../views/apps/contenttopics/store/index'
import cultures from '../views/apps/cultures/store/index'
import educationlevels from '../views/apps/educationlevels/store/index'
import expertiselevels from '../views/apps/expertiselevels/store/index'
import incomelevels from '../views/apps/incomelevels/store/index'
import interests from '../views/apps/interests/store/index'
import intelligencerules from '../views/apps/intelligencerules/store/index'
import intelligenceterms from '../views/apps/intelligenceterms/store/index'
import intelligencetags from '../views/apps/intelligencetags/store/index'
import genders from '../views/apps/genders/store/index'
import lifestages from '../views/apps/lifestages/store/index'
import mediaFolder from '../views/apps/media/store/index'
import materials from '../views/apps/materials/store/index'
import products from '../views/apps/products/store/index'
import profiles from '../views/apps/profiles/store/index'
import settings from '../views/apps/settings/store/index'
import sizes from '../views/apps/sizes/store/index'
import tags from '../views/apps/tags/store/index'
import tones from '../views/apps/tones/store/index'
import values from '../views/apps/values/store/index'
import audienceProfile from '../views/apps/audiencesProfiles/store/index'
import profileSetting from '../views/apps/personalInfo/store/index'

const rootReducer = {
  navbar,
  cache,
  layout,
  auth,
  common,
  user,
  attributes,
  attributeSets,
  brands,
  category,
  channels,
  colors,
  contentjobs,
  contentformats,
  contenttopics,
  genders,
  intelligencetags,
  intelligenceterms,
  intelligencerules,
  materials,
  mediaFolder,
  products,
  profiles,
  settings,
  sizes,
  tags,
  tones,
  agegroups,
  behavioralcharacteristics,
  cultures,
  educationlevels,
  expertiselevels,
  incomelevels,
  interests,
  lifestages,
  values,
  audienceProfile,
  profileSetting
}

export default rootReducer
