// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_API } from '../../../../configs/api'
import axios from 'axios'
import { tenatHeader } from '../../../../configs/header'
import { saveCache } from '../../../../redux/commonReducer/cache'

export const getAllData = createAsyncThunk('appProfileSetting/getAllData', async (force, { getState, dispatch }) => {
  const Header = tenatHeader()
  const allAPIKey = getState().cache.allAPIKey
  if (allAPIKey && !force) {
  }
  dispatch(saveCache({ name: 'allAPIKey', value: new Date().getTime() }))
  const response = await axios.get(`${BASE_API}/api/user/api-keys `, Header)
  return response.data
})

export const billingAddress = createAsyncThunk('appProfileSetting/billingAddress', async () => {
  const Header = tenatHeader()
  const response = await axios.get(`${BASE_API}/api/user/billing-address`, Header)
  return response.data
})

export const appProfileSettingSlice = createSlice({
  name: 'appProfileSetting',
  initialState: {
    firstPage: [],
    total: 1,
    params: {},
    allData: [],
    billingAddress: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        if (action.payload?.success) {
          state.allData = (action?.payload?.data || []).map(el => ({
            ...el,
            value: el?.id,
            label: el?.name
          }))
        }
      })
      .addCase(billingAddress.fulfilled, (state, action) => {
        if (action.payload?.success) {
          state.billingAddress = action?.payload?.data || {}
        }
      })
  }
})

export default appProfileSettingSlice.reducer
