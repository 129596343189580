// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_API } from '../../../../configs/api'
import axios from 'axios'
import { tenatHeader } from '../../../../configs/header'
import { saveCache } from '../../../../redux/commonReducer/cache'

export const getData = createAsyncThunk('audienceProfile/getData', async (force, { getState, dispatch }) => {
  const Header = tenatHeader()
  const audienceProfiles = getState().cache.audienceProfiles
  if (audienceProfiles && !force) {
    return
  }
  dispatch(saveCache({ name: 'audienceProfiles', value: new Date().getTime() }))
  const response = await axios.get(`${BASE_API}/api/tenant/profiles`, Header)
  return response.data
})

export const createData = createAsyncThunk('audienceProfile/createData', async params => {
  const Header = tenatHeader()
  const response = await axios.post(`${BASE_API}/api/tenant/profiles`, params, Header)
  if (response.data.success) {
    return response.data.data
  }
})

export const retrieveData = createAsyncThunk('audienceProfile/retrieveData', async id => {
  const Header = tenatHeader()
  const response = await axios
    .get(`${BASE_API}/api/tenant/profiles/${id}`, Header)
    .catch(error => showError(error?.response?.data?.message))
  return response.data
})

export const audienceProfileSlice = createSlice({
  name: 'audienceProfileSlice',
  initialState: {
    data: [],
    firstPage: [],
    total: 1,
    params: {},
    allData: [],
    editAudience: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = (action?.payload?.data || []).map(el => ({
          ...el,
          ID: el?.id,
          label: el?.name
        }))
        state.params = action?.payload?.params
        state.total = action?.payload?.totalPages || 1
      })
      .addCase(retrieveData.fulfilled, (state, action) => {
        state.editAudience = action?.payload?.data || {}
      })
  }
})

export default audienceProfileSlice.reducer
