// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { AUTH_USER } from '../configs/storage'

import AuthService from '../services/authService'
import { currentUser } from '@src/@core/auth'
import { showSuccess, showError } from '../helper/toastHelper'
import { StorageKey } from '../constants/storage'
import { tenatHeader } from '../configs/header'
import { BASE_API } from '../configs/api'
import axios from 'axios'

const config = useJwt.jwtConfig

export const getAuthUser = createAsyncThunk('users/getAuthUser', async () => {
  try {
    await AuthService.withCsrfAsync()
    const response = await AuthService.getAuthUser()
    const user = response.data.data
    return user
  } catch (ex) {
    console.log(ex)
    if (!ex.response) {
      throw ex
    }

    return Promise.reject(ex)
  }
})

export const logoutAsync = createAsyncThunk('users/logoutAsync', async ({ showMessage } = { showMessage: true }) => {
  try {
    await AuthService.withCsrfAsync()
    const response = await AuthService.logout()
    return { ...response, showMessage }
  } catch (ex) {
    console.log(ex)
    return Promise.reject(ex)
  }
})

export const updateRememberUserSettings = createAsyncThunk('users/updateRememberUserSettings', async params => {
  try {
    const Header = tenatHeader()
    // const params = {
    //   settings: {
    //       mediaListingType: "list",
    //       tableListingCount: "10",
    //       GuiLanguage: "de"
    //   }
    // }
    const response = await axios.put(`${BASE_API}/api/mysettings`, params, Header)
    return response?.data
  } catch (ex) {
    console.log(ex)
    return Promise.reject(ex)
  }
})

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: currentUser,
    loading: true,
    error: '',
    isUserLoggedIn: currentUser.token !== undefined
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.clear()
      localStorage.setItem(StorageKey.USER_DATA, JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
      state.isUserLoggedIn = true
    },
    updateAvatar: (state, action) => {
      const { tenant_id, image } = action.payload
      state.userData.tenant_id = tenant_id
      state.userData.image = image
      const newCurrentUser = JSON.parse(localStorage.getItem('userData')) || {}
      newCurrentUser.tenant_id = tenant_id
      newCurrentUser.image = image
      localStorage.setItem('userData', JSON.stringify(newCurrentUser))
    },
    deleteAvatar: state => {
      state.userData.image = null
      const newCurrentUser = JSON.parse(localStorage.getItem('userData')) || {}
      newCurrentUser.image = null
      localStorage.setItem('userData', JSON.stringify(newCurrentUser))
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAuthUser.pending, state => {
        state.loading = true
      })
      .addCase(getAuthUser.fulfilled, (state, action) => {
        state.loading = false
        state[AUTH_USER] = action.payload
        localStorage.setItem(AUTH_USER, JSON.stringify(action.payload))
      })
      .addCase(getAuthUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        const res = action.payload.data

        if (res.success) {
          state.userData = {}
          state[config.storageTokenKeyName] = null
          state[config.storageRefreshTokenKeyName] = null

          // ** Remove user, accessToken & refreshToken from localStorage
          localStorage.removeItem(StorageKey.USER_DATA)
          localStorage.removeItem(config.storageTokenKeyName)
          localStorage.removeItem(config.storageRefreshTokenKeyName)
          localStorage.clear()
          state.isUserLoggedIn = false
          if (action.payload.showMessage) {
            showSuccess(res.message)
          }
        } else {
          if (action.payload.showMessage) {
            showSuccess(res.message)
          }
        }
      })
      .addCase(logoutAsync.rejected, action => {
        const err = action.error
        console.error(err)
        const errorMessage = err.response?.data?.message
        showError(errorMessage || err.message)
      })
      .addCase(updateRememberUserSettings.fulfilled, (state, action) => {
        const { data } = action.payload
        state.userData.settings = data?.settings
      })
  }
})

export const { handleLogin, updateAvatar, deleteAvatar } = authSlice.actions

export default authSlice.reducer
