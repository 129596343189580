// ** I18n Imports
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import common_en from './../../trans/enLocales'
import common_de from './../../trans/deLocales'

const lang = localStorage.getItem('i18nextLng')

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: lang || 'en',
    resources: {
      en: { translations: common_en },
      de: { translations: common_de }
    },
    fallbackLng: lang || 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
