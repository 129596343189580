import { StorageKey } from '../constants/storage'

export const adminHeader = () => {
  const getAccessToken = () => JSON.parse(localStorage.getItem(StorageKey.ACCESS_TOKEN))
  const token = getAccessToken()
  const Header = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  }
  return Header
}

export const tenatHeader = () => {
  const getAccessToken = () => JSON.parse(localStorage.getItem(StorageKey.ACCESS_TOKEN))
  const getUserData = () => JSON.parse(localStorage.getItem(StorageKey.USER_DATA))
  const token = getAccessToken()
  const tenantID = getUserData()?.tenant_id
  const Header = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Tenant': tenantID
    }
  }
  return Header
}
