// ** Redux Imports
import rootReducer from './rootReducer'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

const persistedReducer = persistReducer(
  {
    key: 'root',
    blacklist: 'cache',
    storage
  },
  combineReducers(rootReducer)
)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  }
})

export { store }
