// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_API } from '../../../../configs/api'
import axios from 'axios'
import { tenatHeader } from '../../../../configs/header'
import { saveCache } from '../../../../redux/commonReducer/cache'

export const getAllData = createAsyncThunk('appValues/getAllData', async (force, { getState, dispatch }) => {
  const Header = tenatHeader()
  const allValues = getState().cache.allValues
  if (allValues && !force) {
    return
  }
  dispatch(saveCache({ name: 'allValues', value: new Date().getTime() }))
  const response = await axios.get(`${BASE_API}/api/tenant/values`, Header)
  return response.data
})

export const getData = createAsyncThunk('appValues/getData', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios.get(`${BASE_API}/api/tenant/values`, config)
  if (response.data.success) {
    return {
      params,
      data: response?.data?.data || [],
      totalPages: response?.data?.data?.total || 1
    }
  }
})

export const retrieveData = createAsyncThunk('appValues/retrieveData', async id => {
  const Header = tenatHeader()
  const response = await axios
    .get(`${BASE_API}/api/tenant/values/${id}`, Header)
    .catch(error => showError(error?.response?.data?.message))
  return response.data
})

export const appValuesSlice = createSlice({
  name: 'appValues',
  initialState: {
    data: [],
    firstPage: [],
    total: 1,
    params: {},
    allData: [],
    editValues: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        if (action.payload?.success) state.allData = (action?.payload?.data || []).map(el => ({
            ...el,
            value: el?.id,
            label: el?.name
          }))
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action?.payload?.data || []
        state.params = action?.payload?.params
        state.total = action?.payload?.totalPages || 1
        if (action?.payload?.params?.firstPage) {
          state.firstPage = action?.payload?.data || []
        }
      })
      .addCase(retrieveData.fulfilled, (state, action) => {
        state.editValues = action?.payload?.data || {}
      })
  }
})

export default appValuesSlice.reducer
