// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_API } from '../../../../configs/api'
import { tenatHeader } from '../../../../configs/header'

// ** Axios Imports
import axios from 'axios'
import { saveCache } from '../../../../redux/commonReducer/cache'
export const getAllData = createAsyncThunk('contenttopic/getAllData', async (force, { getState, dispatch }) => {
  const Header = tenatHeader()
  const allContenttopics = getState().cache.allContenttopics
  if (allContenttopics > 0 && !force) return
  dispatch(saveCache({ name: 'allContenttopics', value: new Date().getTime() }))
  const response = await axios
    .get(`${BASE_API}/api/tenant/contenttopics`, Header)
    .catch(error => showError(error?.response?.data?.message))
  return response.data
})

export const getData = createAsyncThunk('contenttopic/getData', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios
    .get(`${BASE_API}/api/tenant/contenttopics`, config)
    .catch(error => showError(error?.response?.data?.message))

  return {
    params,
    data: response?.data?.data?.data,
    totalPages: response?.data?.data?.total
  }
})

export const retrieveData = createAsyncThunk('contenttopic/retrieveData', async id => {
  const Header = tenatHeader()
  const response = await axios
    .get(`${BASE_API}/api/tenant/contenttopics/${id}`, Header)
    .catch(error => showError(error?.response?.data?.message))
  return response.data
})

export const treeData = createAsyncThunk('contenttopic/treeData', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const tree = await axios
    .get(`${BASE_API}/api/tenant/custom-contenttopics-list`, config)
    .catch(error => showError(error?.response?.data?.message))
  return tree.data
})

export const appUsersSlice = createSlice({
  name: 'contenttopic',
  initialState: {
    data: [],
    contenttopic: [],
    total: 1,
    params: {},
    saveTree: [],
    editContenttopic: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        if (action?.payload?.success) {
          state.contenttopic = (action?.payload?.data || []).map(el => ({
            ...el,
            value: el?.id,
            label: el?.name
          }))
          state.total = action?.payload?.data?.total
        }
      })
      .addCase(treeData.fulfilled, (state, action) => {
        state.saveTree = action?.payload?.data || []
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = (action?.payload?.data || []).map((el, index) => ({
          ...el,
          ID: el.id,
          Name: el?.name,
          label: el?.name,
          position: index
        }))
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(retrieveData.fulfilled, (state, action) => {
        state.editContenttopic = action?.payload?.data || {}
      })
  }
})

export default appUsersSlice.reducer
