import { createSlice } from '@reduxjs/toolkit'

export const cacheSlice = createSlice({
  name: 'cache',
  initialState: {
    countries: undefined,
    languages: undefined,
    permissions: undefined
  },
  reducers: {
    saveCache: (state, action) => {
      state[action.payload.name] = action.payload.value
    }
  }
})

export const { saveCache } = cacheSlice.actions

export default cacheSlice.reducer
