// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_API } from '../../../../configs/api'
import { showError, showSuccess } from '../../../../helper/toastHelper'
import axios from 'axios'
import { saveCache } from '../../../../redux/commonReducer/cache'

const authenticationAccessToken = () => {
  const accessToken = localStorage.getItem('accessToken')
  const token = accessToken && accessToken?.substring(1, accessToken?.length - 1)
  const Header = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  }
  return Header
}

export const getAllData = createAsyncThunk('appUsers/getAllData', async (force, { getState, dispatch }) => {
  const Header = authenticationAccessToken()
  const allUsers = getState().cache.allUsers
  if (allUsers > 0 && !force) return
  dispatch(saveCache({ name: 'allUsers', value: new Date().getTime() }))
  const response = await axios.get(`${BASE_API}/api/users`, Header)
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const Header = authenticationAccessToken()
  const config = {
    headers: Header.headers,
    params
  }

  const response = await axios.get(`${BASE_API}/api/users`, config)
  return {
    params,
    data: response?.data?.data?.data,
    totalPages: response?.data?.data?.total
  }
})

export const addUser = createAsyncThunk('appUsers/addUser', async user => {
  const Header = authenticationAccessToken()
  const response = await axios.post(`${BASE_API}/api/users`, user, Header)
  return response
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  const Header = authenticationAccessToken()
  const response = await axios.delete(`${BASE_API}/api/users/${id}`, Header)
  if (response?.data?.success) {
    await dispatch(getState())
    showSuccess(response?.data?.message)
  } else {
    showError(response?.data?.message)
  }
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        if (action.payload?.success) state.allData = action?.payload?.data?.data || action?.payload?.data || []
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action?.payload?.data
        state.params = action?.payload?.params
        state.total = action?.payload?.totalPages
      })
  }
})

export default appUsersSlice.reducer
