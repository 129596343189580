import axios from 'axios'
import { BASE_API } from '../configs/api'

export const authClient = axios.create({
  baseURL: BASE_API,
  withCredentials: true // required to handle the CSRF token
})

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default {
  withCsrf(callback) {
    authClient.get('/sanctum/csrf-cookie').then(response => {
      callback(response)
    })
  },
  getAuthUser() {
    return authClient.get('/api/users/auth')
  },
  withCsrfAsync() {
    return authClient.get('/sanctum/csrf-cookie')
  },
  forgotPassword(data) {
    return authClient.post('/api/forgot-password', data)
  },
  resetPassword(data) {
    return authClient.post('/api/reset-password', data)
  },
  logout() {
    return authClient.post('/api/logout', {})
  }
}
