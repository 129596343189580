// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_API } from '../../../../configs/api'
import axios from 'axios'
import { tenatHeader } from '../../../../configs/header'

export const getAllData = createAsyncThunk('appMediaFolder/getAllData', async () => {
  const Header = tenatHeader()
  const response = await axios.get(`${BASE_API}/api/tenant/mediafolder`, Header)
  return response.data
})

export const getData = createAsyncThunk('appMediaFolder/getData', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios.get(`${BASE_API}/api/tenant/mediafolder`, config)
  if (response.data.success) {
    return {
      params,
      data: response?.data?.data?.data || [],
      totalPages: response?.data?.data?.total || 1
    }
  }
})

export const getMediaFileById = createAsyncThunk('appMediaFolder/getMediaFileByID', async id => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers
  }
  const response = await axios.get(`${BASE_API}/api/tenant/media/${id}`, config)
  if (response.data.success) {
    return {
      data: response?.data?.data || []
    }
  }
})

export const getMediaFileData = createAsyncThunk('appMediaFolder/getMediaFileData', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios.get(`${BASE_API}/api/tenant/media`, config)
  if (response.data.success) {
    if (params.collection_name) {
      return {
        params,
        data: response?.data?.data || [],
        totalPages: response?.data?.data?.total || 1,
        folderList: []
      }
    } else {
      return {
        params,
        data: response?.data?.data || [],
        totalPages: response?.data?.data?.total || 1
      }
    }
  }
})

export const getDropDwonList = createAsyncThunk('appMediaFolder/dropdownlist', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios.get(`${BASE_API}/api/tenant/mediafolder`, config)
  if (response.data.success) {
    return {
      params,
      data: response?.data?.data || [],
      totalPages: response?.data?.data?.total || 1
    }
  }
})

export const getSpecificFolderData = createAsyncThunk('appMediaFolder/getSpecificFolderData', async params => {
  const Header = tenatHeader()
  const newParams = {
    sort_order: params.sort_order,
    sort_by: params.sort_by,
    term: params.term,
    page: params.page,
    per_page: params.per_page
  }
  const config = {
    headers: Header.headers,
    params: newParams
  }
  const response = await axios.get(`${BASE_API}/api/tenant/mediafolder/${params.id}`, config)
  if (response.data.success) {
    return {
      data: response?.data?.data || [],
      totalPages: response?.data?.total || 1
    }
  }
})

export const updateMedia = createAsyncThunk('appMediaFolder/updateMedia', async ({ mediaId, params }) => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers
  }

  const response = await axios.post(`${BASE_API}/api/tenant/media/${mediaId}?_method=PUT`, params, config)
  if (response.data.success) {
    return response.data.data
  }
})

export const createMedia = createAsyncThunk('appMediaFolder/createMedia', async formData => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers
  }
  return await axios.post(`${BASE_API}/api/tenant/media`, formData, config)
})

export const getMediaDetail = createAsyncThunk('appMediaFolder/getMediaDetail', async id => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers
  }
  return await axios.get(`${BASE_API}/api/tenant/media/${id}`, config)
})

export const appMediaFolderSlice = createSlice({
  name: 'appBrands',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    dropDownList: [],
    mediaFilelist: [],
    backButtonData: {},
    mediaDetail: {}
  },
  reducers: {
    resetMediaDetail: state => {
      state.mediaDetail = {}
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        if (action.payload?.success) state.allData = action?.payload?.data || []
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.backButtonData = {}
        state.data = action?.payload?.data || []
        state.params = action?.payload?.params
        state.total = action?.payload?.totalPages || 1
      })
      .addCase(getDropDwonList.fulfilled, (state, action) => {
        state.dropDownList = action?.payload?.data?.data || action?.payload?.data || []
      })
      .addCase(getMediaFileData.fulfilled, (state, action) => {
        state.mediaFilelist = action?.payload?.data || []
        if (action.payload.folderList) {
          state.data = []
        }
      })
      .addCase(getSpecificFolderData.fulfilled, (state, action) => {
        state.backButtonData = action?.payload?.data
        state.mediaFilelist = action?.payload?.data?.media_files || []
        state.data = action?.payload?.data?.media_folder_hirarchy || []
      })
      .addCase(getMediaFileById.fulfilled, (state, action) => {
        state.mediaDetail = action?.payload?.data || []
      })
  }
})

export const { resetMediaDetail } = appMediaFolderSlice.actions
export default appMediaFolderSlice.reducer
