// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_API } from '../../../../configs/api'
import axios from 'axios'
import { tenatHeader } from '../../../../configs/header'

export const getAllData = createAsyncThunk('appChannels/getAllData', async () => {
  const Header = tenatHeader()
  const response = await axios.get(`${BASE_API}/api/tenant/channels`, Header)
  return response.data
})

export const getData = createAsyncThunk('appChannels/getData', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios.get(`${BASE_API}/api/tenant/channels`, config)
  if (response.data.success) {
    return {
      params,
      data: response?.data?.data || [],
      totalPages: response?.data?.total || 1
    }
  }
})

export const getAllAttributes = createAsyncThunk('appProducts/getAllAttributes', async () => {
  const Header = tenatHeader()
  const response = await axios.get(`${BASE_API}/api/tenant/attributes?per_page=10000`, Header)
  return response?.data?.data || []
})

export const getChannelType = createAsyncThunk('appChannels/getChannelType', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios.get(`${BASE_API}/api/tenant/channel-types`, config)
  return response.data
})

export const retrieveData = createAsyncThunk('appChannels/retrieveData', async id => {
  const Header = tenatHeader()
  const response = await axios
    .get(`${BASE_API}/api/tenant/channels/${id}`, Header)
    .catch(error => showError(error?.response?.data?.message))
  return response.data
})

export const appChannelsSlice = createSlice({
  name: 'appChannels',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    editChannels: {},
    firstPage: [],
    channelTypes: []
  },
  reducers: {
    saveImportedFileData: (state, action) => {
      const data = action.payload
      state.importedFileData = data
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        if (action.payload?.success) state.allData = action?.payload?.data || []
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action?.payload?.data || []
        state.params = action?.payload?.params
        state.total = action?.payload?.totalPages || 1
        if (action?.payload?.params?.firstPage) {
          state.firstPage = action?.payload?.data || []
        }
      })
      .addCase(retrieveData.fulfilled, (state, action) => {
        state.editChannels = action?.payload?.data || {}
      })
      .addCase(getChannelType.fulfilled, (state, action) => {
        if (action.payload?.success) state.channelTypes = (action?.payload?.data || []).map(el => ({
            ...el,
            value: el?.id,
            label: el?.name
          }))
      })
  }
})

export const { saveImportedFileData } = appChannelsSlice.actions

export default appChannelsSlice.reducer
