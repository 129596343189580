// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_API } from '../../../../configs/api'
import axios from 'axios'
import { tenatHeader } from '../../../../configs/header'
import { saveCache } from '../../../../redux/commonReducer/cache'

export const getAllData = createAsyncThunk('appBrands/getAllData', async (force, { getState, dispatch }) => {
  const Header = tenatHeader()
  const allAttributes = getState().cache.allAttributes
  if (allAttributes && !force) {
    return
  }
  dispatch(saveCache({ name: 'allAttributes', value: new Date().getTime() }))
  const response = await axios
    .get(`${BASE_API}/api/tenant/attributesets`, Header)
    .catch(error => showError(error?.response?.data?.message))
  return response.data
})

export const getData = createAsyncThunk('appBrands/getData', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios
    .get(`${BASE_API}/api/tenant/attributesets`, config)
    .catch(error => showError(error?.response?.data?.message))
  if (response.data.success) {
    return {
      params,
      data: response?.data?.data?.data || [],
      totalPages: response?.data?.data?.total || 1
    }
  }
})

export const retriveData = createAsyncThunk('category/retriveData', async id => {
  const Header = tenatHeader()
  const response = await axios
    .get(`${BASE_API}/api/tenant/attributesets/${id}`, Header)
    .catch(error => showError(error?.response?.data?.message))
  return response.data
})

export const appAttributeSetsSlice = createSlice({
  name: 'appAttributeSets',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    editAttributeSets: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        if (action.payload?.success) state.allData = (action?.payload?.data?.data || action?.payload?.data || []).map(el => ({
            ...el,
            value: el?.id,
            label: el?.name
          }))
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = (action?.payload?.data || []).map(el => ({
          ...el,
          ID: el?.id,
          label: el?.name
        }))
        state.params = action?.payload?.params
        state.total = action?.payload?.totalPages || 1
      })
      .addCase(retriveData.fulfilled, (state, action) => {
        state.editAttributeSets = {
          ...(action?.payload?.data || {}),
          list_values: (action?.payload?.data?.list_values || []).map(el => ({
            ...el,
            value: el?.name,
            label: el?.name
          }))
        }
      })
  }
})

export default appAttributeSetsSlice.reducer
