// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_API } from '../../../../configs/api'
import axios from 'axios'
import { tenatHeader } from '../../../../configs/header'
import { Configuration, OpenAIApi } from 'openai'

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPEN_AI_API_KEY
})

const openai = new OpenAIApi(configuration)

export const getAllData = createAsyncThunk('appProducts/getAllData', async () => {
  const Header = tenatHeader()
  const response = await axios.get(`${BASE_API}/api/tenant/products`, Header)
  return response.data
})

export const getData = createAsyncThunk('appProducts/getData', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios.get(`${BASE_API}/api/tenant/products`, config)
  if (response.data.success) {
    return {
      params,
      data: response?.data?.data?.data || [],
      totalPages: response?.data?.data?.total || 1
    }
  }
})

export const retrieveData = createAsyncThunk('appProducts/retrieveData', async id => {
  const Header = tenatHeader()
  const response = await axios
    .get(`${BASE_API}/api/tenant/products/${id}`, Header)
    .catch(error => showError(error?.response?.data?.message))
  return response.data
})

export const systemAttributeset = createAsyncThunk('appProducts/systemAttributeset', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios
    .get(`${BASE_API}/api/tenant/attributesetlist`, config)
    .catch(error => showError(error?.response?.data?.message))
  return response.data
})

export const baseAttributesetListing = createAsyncThunk('appProducts/baseAttributesetListing', async cb => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params: {
      key: 'base'
    }
  }
  const response = await axios
    .get(`${BASE_API}/api/tenant/attributesetlist`, config)
    .catch(error => showError(error?.response?.data?.message))
  cb?.(response.data?.data ?? [])
  return response.data
})

export const nonSystemAttributeset = createAsyncThunk('appProducts/nonSystemAttributeset', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios
    .get(`${BASE_API}/api/tenant/attributesetlist`, config)
    .catch(error => showError(error?.response?.data?.message))
  return response.data
})

export const getAllAttributes = createAsyncThunk('appProducts/getAllAttributes', async () => {
  const Header = tenatHeader()
  const response = await axios.get(`${BASE_API}/api/tenant/attributes?per_page=10000`, Header)
  return response?.data?.data || []
})

export const getProductMediaListing = createAsyncThunk('appProducts/getProductMediaListing', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios.get(`${BASE_API}/api/tenant/media`, config)
  if (response.data.success) {
    return {
      params,
      data: response?.data?.data.data || response?.data?.data || [],
      totalPages: response?.data?.data?.total || 1
    }
  }
})

export const getVariantsAttributeDropdown = createAsyncThunk(
  'appProducts/getVariantsAttributeDropdown',
  async params => {
    const Header = tenatHeader()
    const config = {
      headers: Header.headers,
      params
    }
    const response = await axios.get(`${BASE_API}/api/tenant/attributes`, config)
    if (response.data.success) {
      return {
        params,
        data: (response?.data?.data || []).map(el => ({
          ...el,
          label: el.name,
          value: el.name
        })),
        totalPages: response?.data?.data?.total || 1
      }
    }
  }
)

export const getVariantsListing = createAsyncThunk('appProducts/getVariantsListing', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    params
  }
  const response = await axios.get(`${BASE_API}/api/tenant/products`, config)
  if (response.data.success) {
    return {
      params,
      data: response?.data?.data || [],
      totalPages: response?.data?.data?.total || 1
    }
  }
})

export const getPromptByAttributeKey = createAsyncThunk('appProducts/getPromptByAttributeKey', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers
  }
  const { productId, attributeKey } = params
  const response = await axios.get(`${BASE_API}/api/tenant/products/${productId}/prompt/${attributeKey}`, config)
  if (response.data.success) {
    return response.data.data
  }
})

export const createCopyPrompt = createAsyncThunk('appProducts/createCopyPrompt', async params => {
  const Header = tenatHeader()
  const result = await new Promise(resolve => {
    openai
      .createChatCompletion({
        model: params.openAiModel,
        messages: [
          {
            role: 'system',
            content: params.prompt
          },
          {
            role: 'user',
            content: params.input
          }
        ],
        max_tokens: params.openAiTokens
      })
      .then(data => resolve({ copy: data?.data?.choices?.[0]?.message?.content }))
  })

  return result
})

export const updateAttributeProduct = createAsyncThunk(
  'appProducts/updateAttributeProduct',
  async ({ productId, params }) => {
    const Header = tenatHeader()
    const response = await axios.put(`${BASE_API}/api/tenant/products/${productId} `, params, Header)
    if (response.data.success) {
      return response.data.data
    }
  }
)

export const superResolutionImage = createAsyncThunk('appProducts/superResolutionImage', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    responseType: 'blob'
  }
  const response = await axios.post(`${BASE_API}/api/tenant/service/super-resolution`, params, config)
  return response.data
})

export const replaceBackgroundImage = createAsyncThunk('appProducts/replaceBackgroundImage', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    responseType: 'blob'
  }
  const response = await axios.post(`${BASE_API}/api/tenant/service/replace-background`, params, config)
  return response.data
})

export const getMediaBinary = createAsyncThunk('appProducts/getMediaBinary', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    responseType: 'blob'
  }
  const response = await axios.get(`${BASE_API}/api/tenant/media/${params.id}/binary`, config)
  return response.data
})

export const removeBackgroundImage = createAsyncThunk('appProducts/removeBackgroundImage', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    responseType: 'blob'
  }
  const response = await axios.post(`${BASE_API}/api/tenant/service/remove-background`, params, config)
  return response.data
})

export const recoloringImage = createAsyncThunk('appProducts/recoloring', async params => {
  const Header = tenatHeader()
  const config = {
    headers: Header.headers,
    responseType: 'blob'
  }
  const response = await axios.post(`${BASE_API}/api/tenant/service/recoloring`, params, config)
  return response.data
})

export const updateProduct = createAsyncThunk('appProducts/updateProduct', async ({ productId, params }) => {
  const Header = tenatHeader()
  const response = await axios.post(`${BASE_API}/api/tenant/products/${productId}?_method=PUT`, params, Header)
  if (response.data.success) {
    return response.data.data
  }
})

export const appProductsSlice = createSlice({
  name: 'appProducts',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    editProducts: {},
    importedFileData: [],
    allAttributes: [],
    systemAttributesetListing: [],
    nonSystemAttributesetListing: [],
    baseAttributesetListing: [],
    productMeidaListing: [],
    productMeidaListingTotal: 1,
    variantDropdownValues: [],
    variantListing: [],
    page: 0,
    rowsPerPage: 10
  },
  reducers: {
    saveImportedFileData: (state, action) => {
      const data = action.payload
      state.importedFileData = data
    },
    clearEditProducts: state => {
      state.editProducts = {}
    },
    savePage: (state, action) => {
      state.page = action.payload
    },
    saveRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        if (action.payload?.success) state.allData = (action?.payload?.data || []).map(el => ({
            ...el,
            label: el.name,
            value: el.id
          }))
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action?.payload?.data || []
        state.params = action?.payload?.params
        state.total = action?.payload?.totalPages || 1
      })
      .addCase(retrieveData.fulfilled, (state, action) => {
        state.editProducts = action?.payload?.data || {}
      })
      .addCase(systemAttributeset.fulfilled, (state, action) => {
        state.systemAttributesetListing = action?.payload?.data || []
      })
      .addCase(nonSystemAttributeset.fulfilled, (state, action) => {
        state.nonSystemAttributesetListing = action?.payload?.data || []
      })
      .addCase(baseAttributesetListing.fulfilled, (state, action) => {
        state.baseAttributesetListing = action?.payload?.data || []
      })
      .addCase(getAllAttributes.fulfilled, (state, action) => {
        const allAttributes = action?.payload?.data || []
        state.allAttributes = allAttributes || []
      })
      .addCase(getProductMediaListing.fulfilled, (state, action) => {
        state.productMeidaListing = action.payload.data || []
        state.productMeidaListingTotal = action.payload.totalPages
      })
      .addCase(getVariantsAttributeDropdown.fulfilled, (state, action) => {
        state.variantDropdownValues = action.payload.data || []
      })
      .addCase(getVariantsListing.fulfilled, (state, action) => {
        state.variantListing = action.payload.data || []
      })
  }
})

export const { saveImportedFileData, clearEditProducts, savePage, saveRowsPerPage } = appProductsSlice.actions

export default appProductsSlice.reducer
