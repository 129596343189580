import { BASE_API } from '../../configs/api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { adminHeader, tenatHeader } from '../../configs/header'
import { saveCache } from './cache'

export const countries = createAsyncThunk('api/common/countries', async (force = false, { getState, dispatch }) => {
  const Header = adminHeader()
  const countries = getState().cache.countries
  if (countries && !force) {
    return
  }
  dispatch(saveCache({ name: 'countries', value: new Date().getTime() }))

  const response = await axios.get(`${BASE_API}/api/settings/countries`, Header)
  return response.data
})
export const languages = createAsyncThunk('api/common/languages', async (force = false, { getState, dispatch }) => {
  const languages = getState().cache.languages
  console.log(languages, force)
  if (languages && !force) {
    return
  }
  const Header = adminHeader()
  dispatch(saveCache({ name: 'languages', value: new Date().getTime() }))
  const response = await axios.get(`${BASE_API}/api/settings/languages`, Header)

  return response.data
})
export const timezones = createAsyncThunk('api/common/timezones', async () => {
  const Header = adminHeader()
  const response = await axios.get(`${BASE_API}/api/settings/timezones`, Header)
  return response.data
})
export const currencies = createAsyncThunk('api/common/currencies', async (force, { getState, dispatch }) => {
  const Header = adminHeader()
  const allCurrencies = getState().cache.currencies
  if (allCurrencies && !force) {
    return
  }
  dispatch(saveCache({ name: 'currencies', value: new Date().getTime() }))
  const response = await axios.get(`${BASE_API}/api/settings/currencies`, Header)
  return response.data
})

export const attributContentListing = createAsyncThunk(
  'api/common/attributeListing',
  async (force, { getState, dispatch }) => {
    const Header = tenatHeader()
    const attributeContentListing = getState().cache.attributeContentListing
    if (attributeContentListing && !force) {
      return
    }
    dispatch(
      saveCache({
        name: 'attributeContentListing',
        value: new Date().getTime()
      })
    )
    const response = await axios.get(`${BASE_API}/api/tenant/attributecontenttype`, Header)
    return response.data
  }
)

export const taxes = createAsyncThunk('api/common/taxes', async (_, { getState, dispatch }) => {
  const Header = tenatHeader()
  const taxes = getState().cache.taxes
  if (taxes && !force) {
    return
  }
  dispatch(saveCache({ name: 'taxes', value: new Date().getTime() }))
  const response = await axios.get(`${BASE_API}/api/tenant/taxes`, Header)
  return response.data
})

export const productTypes = createAsyncThunk('api/common/productType', async (force, { getState, dispatch }) => {
  const Header = tenatHeader()
  const productTypes = getState().cache.productTypes
  if (productTypes && !force) {
    return
  }
  dispatch(saveCache({ name: 'productTypes', value: new Date().getTime() }))
  const response = await axios.get(`${BASE_API}/api/tenant/producttypes`, Header)
  return response.data
})

export const tags = createAsyncThunk('api/common/tags', async (force, { getState, dispatch }) => {
  const Header = tenatHeader()
  const tags = getState().cache.tags
  if (tags && !force) {
    return
  }
  dispatch(saveCache({ name: 'tags', value: new Date().getTime() }))
  const response = await axios.get(`${BASE_API}/api/tenant/tags`, Header)
  return response.data
})

export const units = createAsyncThunk('api/common/units', async (force, { getState, dispatch }) => {
  const Header = tenatHeader()
  const units = getState().cache.units
  if (units && !force) {
    return
  }
  const response = await axios.get(`${BASE_API}/api/tenant/units`, Header)
  dispatch(saveCache({ name: 'units', value: new Date().getTime() }))
  return response.data
})

export const permissions = createAsyncThunk(
  'api/common/permissions',
  async ({ shouldLogout, force } = {}, { getState, dispatch }) => {
    if (shouldLogout) {
      return []
    }
    const permissions = getState().cache.permissions
    if (permissions && !force) {
      return
    }
    const Header = adminHeader()
    dispatch(saveCache({ name: 'permissions', value: new Date().getTime() }))
    const response = await axios.get(`${BASE_API}/api/user/permissions`, Header)
    return response.data
  }
)

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    countries: [],
    languages: [],
    timezones: [],
    currencies: [],
    permissions: [],
    attributContentListing: [],
    taxes: [],
    productTypes: [],
    tags: [],
    units: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(countries.fulfilled, (state, action) => {
        if (action?.payload?.data) {
          state.countries = (action?.payload?.data).map(el => ({
            ...el,
            value: el?.id,
            label: el?.name
          }))
        }
      })
      .addCase(languages.fulfilled, (state, action) => {
        if (action?.payload?.data) {
          state.languages = action?.payload?.data.map(el => ({
            ...el,
            value: el?.id,
            label: el?.name
          }))
        }
      })
      .addCase(timezones.fulfilled, (state, action) => {
        state.timezones = action?.payload?.data.map(el => ({
          ...el,
          value: el?.id,
          label: el?.name
        }))
      })
      .addCase(currencies.fulfilled, (state, action) => {
        if (action?.payload?.data) state.currencies = (action?.payload?.data || []).map(el => ({
            ...el,
            value: el?.id,
            label: el?.name
          }))
      })
      .addCase(taxes.fulfilled, (state, action) => {
        if (action?.payload?.data) state.taxes = (action?.payload?.data || []).map(el => ({
            ...el,
            value: el?.id,
            label: el?.name
          }))
      })
      .addCase(productTypes.fulfilled, (state, action) => {
        if (action?.payload?.data) state.productTypes = (action?.payload?.data || []).map(el => ({
            ...el,
            value: el?.id,
            label: el?.name
          }))
      })
      .addCase(permissions.fulfilled, (state, action) => {
        if (action?.payload?.data) {
          state.permissions = action?.payload?.data
        }
      })
      .addCase(tags.fulfilled, (state, action) => {
        if (action?.payload?.data) state.tags = (action?.payload?.data || []).map(el => ({
            ...el,
            value: el?.id,
            label: el?.name
          }))
      })
      .addCase(units.fulfilled, (state, action) => {
        if (action?.payload?.data) state.units = (action?.payload?.data || []).map(el => ({
            ...el,
            value: el?.id,
            label: el?.unit
          }))
      })
      .addCase(attributContentListing.fulfilled, (state, action) => {
        if (action?.payload?.data) state.attributContentListing = action?.payload?.data || []
      })
  }
})

export default commonSlice.reducer
